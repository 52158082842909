<template>
  <div class="duration-component">
    <!-- <ProPlayBtn v-if="iconPlay" class="duration-image" border="#aaa" type="carousel"></ProPlayBtn> -->
    <template v-if="iconPlay !== 'none'">
      <div
        v-if="iconPlay"
        class="duration-image duration-image-radius"
      >
        <i class="duration-image-icon fas fa-play"/>
      </div>
      <div
        v-else
        class="duration-image duration-image-img"
      />
    </template>
    <span class="duration-text">{{ duration | duration }}</span>
  </div>
</template>

<script>

  export default {
    name: 'DurationComponent',
    props: {
      duration: {
        type: Number,
        default: 0,
      },
      iconPlay: {
        type: [String, Boolean],
        default: true,
      },
    },
    // components: {
    // ProPlayBtn
    // }
  };
</script>

<style lang="scss">
	@import './duration.scss';
</style>
