import normalizeImg from 'src/filters/normalizeImg';
import stripHtml from 'src/filters/stripHtml';
import contentLanguage from 'src/filters/contentLanguage';
import deleteParamsUrl from 'src/utils/deleteParamsUrl';

function getData(field, data, parent, defaultValue) {
  if (data && data[field]) {
    return contentLanguage(data, field);
  } if (parent && parent[field]) {
    return contentLanguage(parent, field);
  }
  return defaultValue;
}
export default (data) => {
  const headers = {};
  const metadata = data && data.seo_metadata ? data.seo_metadata : data;
  headers.title = stripHtml(getData('title', metadata, data, 'Web Portal'));
  headers.description = stripHtml(getData('description', metadata, data, null));
  headers.keyword = getData('keywords', metadata, data, [
    // 'ดูหนังออนไลน์',
    // 'ดูหนังฟรี',
    // 'ดูฟรีทีวี',
    // 'ทีวีออนไลน์',
    // 'ฟรีแอนิเมะ',
  ]).join(',');

  headers.image = normalizeImg(
    getData(
      'poster',
      metadata,
      data,
      `${location.origin}/static/favicon/${process.env.VUE_APP_CUSTOMER}/favicon.png`,
    ),
    'image',
    'SD',
    'SD',
  );

  // data && data.description ? stripHtml(contentLanguage(data, 'description')) : null;
  // headers.keyword = data && data.keywords ? data.keywords.join(',') : 'ดูหนังออนไลน์, ดูหนังฟรี, ดูฟรีทีวี, ทีวีออนไลน์, ฟรีแอนิเมะ';

  // let usedAmsImage = data && data.hasOwnProperty('poster') && data.poster;
  // headers.image = usedAmsImage
  //   ? normalizeImg(data.poster, 'image', 'SD', 'SD')
  //   : location.origin + '/portal/static/favicon/favicon.png';
  headers.url = data && data.url
    ? deleteParamsUrl(data.url)
    : deleteParamsUrl(window.location.href);

  headers.type = data && data.type ? data.type : 'website';

  const head = getData('head', metadata, data, {});
  headers.head = Object.assign(head, {
    'meta[property="og:type"]': { content: headers.type },
    // TODO:  Don`t used, facebook have error, while  resolutions is not correct
    // 'meta[property="og:image:width"]': usedAmsImage ? { content: defaultImageWidth } : { content: 300 },
    // 'meta[property="og:image:height"]': usedAmsImage ? { content: defaultImageHeight } : { content: 300 },
    'meta[name="cXenseParse:pageclass"]': { content: 'frontpage' },
    'meta[name="cXenseParse:url"]': { content: headers.url },
  });

  return headers;
};
