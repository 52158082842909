<template>
  <button
    class="btn-arrow btn-arrow-left"
    :disabled="disabled"
  >
    <svg
      height="36"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke-width="3.5"
        d="M17.5 2.5l-15 16 15 16"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
  export default {
    name: 'CarouselArrowLeft',
    props: {
      disabled: {
        type: [Boolean, String],
    
      },
      color: {
        type: String,
        default: 'white',
      },
      hover: {
        type: String,
        default: '#1f55ff',
      },
    
    },
  }
</script>

<style lang="scss">
	@import './carousel-arrows';

</style>
