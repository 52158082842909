<template>
  <section
    v-if="isAvailable"
    :id="getId"
    v-bg-image-js="{ name: background, type: 'image' }"
    class="pro-carousel-component container-fluid"
    :class="{ 'pro-carousel-component-background': !!background }"
  >
    <div
      v-if="title"
      class="
        container-fluid
        bg-block
        py-1
        px-3 px-md-5
        rounded-bottom-0
        pro-carousel-component__header
      "
    >
      <h2 class="default-title pro-carousel-component__title pt-md-4 pt-3">
        {{ title }}
      </h2>
      <router-link
        class="pro-carousel-component__view-all"
        :to="area.itype === 'section_filters_shoprz' ? '/stores' : `/get_section/${area.id}/?link=${area.link}&title=${area.title}`"
      >
        View all
      </router-link>
    </div>
    <carousel-slider
      v-observe-visibility="observeVisibility"
      class="px-3 px-md-5 pb-3 pb-md-4 bg-block rounded-top-0"
      :items="middlewaredItems"
      :row="rows"
      :column="columns"
      :infinite="true"
      :autoplay="autoplay"
      :step="step"
      :arrow-top-center="'item'"
      :breakpoints="breakpoints"
      :static-card-size="getStaticCardSize"
    >
      <template v-slot="{ item }">
        <carousel-component
          :id="`item-${item.id}`"
          :key="item.id"
          :item="item"
          :buh-context="buhContext"
          :shape-type="area.item_image_shape"
          :aspect-ratio="area.aspect_ratio"
          @onMouseEnter="runPreview"
          @onMouseLeave="stopPreview"
        />
      </template>
    </carousel-slider>
  </section>
</template>

<script>
  import CarouselSlider from '@/components/carousel/carousel-slider';
  import CarouselComponent from '@/components/carousel/carousel-component';
  import contentLanguage from '@/filters/contentLanguage.js';
  import ShoprzStore from '@/store/shoprz';
  import { FollowMixin } from '@/plugins/mixin';
  import HlsVideoService from '@/service/hls-video';
  import { amsClient } from '@/service/ams';

  const CarouselHhlService = new HlsVideoService();

  export default {
    name: 'ProCarousel',
    components: {
      CarouselSlider,
      CarouselComponent,
    },
    mixins: [FollowMixin],
    props: {
      area: {
        type: Object,
        required: true,
      },
      itemsProp: {
        type: Array,
        default: () => [],
      },
      buhContext: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        windowSize: 0,
        isHovered: false,
        isVisible: false,
        chachedPreviewMedia: Object.create(null),
      };
    },
    computed: {
      items() {
        return this.itemsProp && this.itemsProp.length > 0
          ? this.itemsProp
          : this.area.items && this.area.items.length > 0
            ? this.area.items
            : [];
      },
      title() {
        return contentLanguage(this.area, 'title');
      },
      grid() {
        return this.area.grid;
      },
      background() {
        return this.area.background;
      },
      isAvailable() {
        return this.items && this.items.length > 0;
      },
      gridConfig() {
        if (this.grid) {
          return this.grid.split('_');
        } else if (this.area.itype === 'section_season') {
          return [1, 5, 1, 'manual'];
        } else if (this.area.item_image_shape === 'horizontal') {
          return [1, 5, 1, 'manual'];
        } else {
          return [1, 8, 1, 'manual'];
        }
      },
      breakpoints() {
        if (this.area.item_image_shape === 'horizontal') {
          return [
            {
              width: 1280,
              column: 4,
            },
            {
              width: 1024,
              column: 4,
            },
            {
              width: 960,
              column: 3,
            },
            {
              width: 760,
              column: 2,
            },
            {
              width: 380,
              column: 1,
            },
          ];
        } else if (
          this.windowSize < 600 &&
          this.area.item_image_shape === 'circle'
        ) {
          return [
            {
              width: 525,
              column: 3,
            },
            {
              width: 370,
              column: 2,
            },
          ];
        } else if (
          this.windowSize < 600 &&
          (this.area.item_image_shape === 'vertical' ||
            this.area.item_image_shape === 'None')
        ) {
          return [
            {
              width: 525,
              column: 3,
            },
          ];
        } else if (this.area.itype === 'section_season') {
          return [
            {
              width: 1024,
              column: 5,
            },
            {
              width: 960,
              column: 4,
            },
            {
              width: 640,
              column: 3,
            },
            {
              width: 380,
              column: 2,
            },
          ];
        } else {
          return [
            {
              width: 1920,
              column: 8,
            },
            {
              width: 1280,
              column: 7,
            },
            {
              width: 1024,
              column: 6,
            },
            {
              width: 960,
              column: 5,
            },
            {
              width: 640,
              column: 4,
            },
            {
              width: 385,
              column: 4,
              step: 3,
            },
          ];
        }
      },
      autoplay() {
        return this.gridConfig[3] === 'auto';
      },
      rows() {
        return parseInt(this.gridConfig[0]); //
      },
      columns() {
        return parseInt(this.gridConfig[1]);
      },
      step() {
        return +this.gridConfig[2];
      },
      getId() {
        return this.area.title.toLowerCase().replace(/\s/g, '');
      },
      getStaticCardSize() {
        return this.area?.item_image_shape && this.windowSize < 600 === 'circle';
      },
      middlewaredItems() {
        if (this.area?.directives?.tag === 'on_air') {
          return this.sortEvents(this.items);
        }

        if (this.area?.page?.includes('Stores')) {
          return this.sortChannels(this.items);
        }

        return this.items;
      },
    },
    created() {
      this.calculateWidth();
      this.$bus.$on('resizeWindow', this.calculateWidth);
    },
    methods: {
      calculateWidth() {
        this.windowSize = window.innerWidth;
      },
      sortEvents(items) {
        const aliveItems = items.slice().filter((item) => item['is_active']);

        const notAliveItems = items
          .slice()
          .filter((item) => !item['is_active'])
          .sort((a, b) => new Date(a.event_start) - new Date(b.event_start));

        return [...aliveItems, ...notAliveItems];
      },
      sortChannels(items) {
        const followedStores = ShoprzStore.followedStores();

        return [...followedStores, ...items];
      },
      async runPreview(item) {
        this.isHovered = true;

        const video = document.getElementById(`item-${item.id}`);

        try {
          if (item.itype === 'item_epg_shop_event') {
            this.chachedPreviewMedia[item.id] = item.media;
          } else {
            if (!this.chachedPreviewMedia[item.id]) {
              const { media } = await amsClient.callAms(item.media, { cache: false });

              this.chachedPreviewMedia[item.id] = media[0].link;
            }
          }

          if (!this.isHovered) {
            return;
          }
          if (this.chachedPreviewMedia[item.id]) {
            CarouselHhlService.bindVideo(video, this.chachedPreviewMedia[item.id]);
          }
          if (CarouselHhlService.canPlay()) {
            await CarouselHhlService.play();
          }
        } catch (e) {
          console.error('preview', e);
        }
      },
      stopPreview() {
        this.isHovered = false;
        CarouselHhlService.clear();
      },
      observeVisibility(isVisible) {
        this.isVisible = isVisible;

        if (!isVisible) {
          CarouselHhlService.clear();
        }
      },
    },
  };
</script>

<style lang="scss" src="./_pro-carousel.scss"></style>
