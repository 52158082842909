<template>
  <div :class="{'sub-label text-nowrap' : !isBtn, 'd-inline-block': isBtn }">
    <button
      v-if="isBtn"
      class=" btn btn-subscribe-big"
    >
      <span v-t="{path: 'SUBSRIPTION.LABEL'}"/>
    </button>
    <span
      v-else
      v-t="{path: 'SUBSRIPTION.LABEL'}"
      class="d-block text-truncate"
    />
  </div>
</template>


<script>
  export default {
    name: 'SubscriptionLabel',
    props: {
      isBtn: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
	// @import '~/bootstrap/scss/_utilities.scss';
	// @import '~/bootstrap/scss/_buttons.scss';
	// @import 'src/style/_shared.scss';
	.sub-label {
		background-color: $subscribe-bg;
		border-radius: $subscribe-border-radius;
		color: $subscribe-color;
		font-weight: 500;
		min-width: 0;
		padding: $subscribe-padding;// 
	}

	// TODO: rewrite code without btn extend
	.btn.btn-subscribe-big {

		background-color: $main-color;
		// border-radius: 16px;
		border-radius: 32px;
		color: $background-color;
		margin-bottom: 8px;
		// @extend .btn;
		margin-left: 0;
		margin-right: 8px;
		margin-top: 8px;
		padding: 6px 16px;
	}

	// @include media-breakpoint-up('md') {
	//   .sub-label {
	//     font-size: 0.5em;
	//   }
	// }
	// @include media-breakpoint-up('xl') {
	//   .sub-label {
	//     font-size: 0.8em;
	//   }
	// }
</style>