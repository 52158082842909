<template>
  <responsive-image
    :src="itemImage"
    :aspect-ratio="aspectRatio"
    :force-resolution-type="['SD', 'SD', 'SD']"
    :title="itemTitle"
    size="contain"
    :content-class="
      'card-thumb-content ' +
        (isNotPromotedLive ? ' is-live ' : '') +
        (showLiveBorder ? ' bordered ' : '')
    "
    :img-class="
      'card-thumb-image is-live' +
        (isNotPromotedLive ? ' is-live ' : '') +
        (showLiveBorder ? ' bordered ' : '')
    "
    :content-style="styleResponsive"
    :responsive-main-style="responsiveMainStyle"
    :preview-mode="isVideo || isEvent"
    :video-id="id"
  >
    <div
      v-if="broadcastingStatus"
      class="card-thumb-overlay-top d-flex flex-row align-items-start justify-content-between"
    >
      <event-status-label class="card-thumb-add" :status="broadcastingStatus" />
      <creator-logo v-if="channelLogo" :logo-image="channelLogo" />
    </div>
    <div
      v-if="isProduct && item.original_price"
      class="card-thumb-overlay-top d-flex flex-row align-items-start justify-content-between"
    >
      <product-status-label class="card-thumb-add" />
    </div>
    <div
      class="card-thumb-overlay d-flex flex-row align-items-end justify-content-between"
      :class="{ 'card-thumb-overlay-color': !isLive }"
    >
      <duration
        v-if="isVisibleDuration"
        :duration="item.duration"
        icon-play="none"
        class="pl-1 card-thumb-add"
      />
      <subscription-label v-if="isVisibleSubscription" class="card-thumb-add" />
      <views
        v-if="isVisibleViews && itemViews"
        v-observe-visibility="viewsVisibilityConfig"
        class="ml-auto pr-1 card-thumb-add"
        :views="itemViews"
      />
    </div>
  </responsive-image>
</template>

<script>
  import responsiveImage from '@/components/reponsive-image.vue';
  import Duration from '@/components/player/duration/duration';
  import Views from '@/components/player/views/views.vue';

  import contentLanguage from '@/filters/contentLanguage.js';
  import SubscriptionLabel from '@/components/subscription-label.vue';
  import EventStatusLabel from '@/components/event-status-label.vue';
  import ProductStatusLabel from '@/components/product-status-label.vue';
  import CreatorLogo from '@/components/creator-logo.vue';
  import { amsClient } from '@/service/ams';

  export default {
    name: 'CardThumbnailImage',
    components: {
      Duration,
      Views,
      responsiveImage,
      SubscriptionLabel,
      EventStatusLabel,
      ProductStatusLabel,
      CreatorLogo,
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
      aspectRatio: {
        type: String,
        default: '16x9',
      },
      hideDuration: {
        type: Boolean,
        default: false,
      },
      hideSubscription: {
        type: Boolean,
        default: false,
      },
      hideViews: {
        type: Boolean,
        default: false,
      },
      responsiveMainStyle: {
        type: String,
        default: '',
      },
      broadcastingStatus: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: null,
      },
    },
    computed: {
      isVideo() {
        return /mov/i.test(this.item.itype);
      },
      isLive() {
        return ['item_live_virtual', 'item_live'].indexOf(this.item.itype) !== -1;
      },
      isEvent() {
        return this.item.itype === 'item_epg_shop_event';
      },
      isProduct() {
        return this.item.itype === 'shop_product';
      },
      isPromotedLive() {
        return this.isLive && this.item.promoted;
      },
      isVisibleDuration() {
        return this.canPlay && this.isVideo && !this.isLive && !this.hideDuration;
      },
      isVisibleViews() {
        return !this.isLive && this.itemViews > -1 && !this.hideViews;
      },
      isVisibleSubscription() {
        return !this.canPlay && !this.hideSubscription;
      },
      viewsVisibilityConfig() {
        return {
          callback: this.addTransparetion,
          intersection: {
            threshold: 0.9,
          },
        };
      },
      srcImageType() {
        return !this.aspectRatio
          ? 'poster'
          : this.aspectRatio === '16x9'
            ? 'backdrop'
            : 'poster';
      },
      srcImageTypeEvent() {
        return !this.aspectRatio
          ? 'poster'
          : this.aspectRatio === '16x9'
            ? 'thumbnail'
            : 'poster';
      },
      itemImage() {
        if (this.item.itype === 'item_epg_shop_event') {
          return this.item[this.srcImageTypeEvent];
        } else if (this.item.itype === 'screen_shoprz' && this.item.creator) {
          return this.item.creator.picture;
        } else {
          return this.item[this.srcImageType];
        }
      },
      channelLogo() {
        return this.item.itype === 'item_epg_shop_event'
          ? this.item.creator.picture
          : null;
      },
      itemTitle() {
        if (this.item.itype === 'screen_shoprz' && this.item.creator) {
          return this.item.creator.name;
        } else {
          return contentLanguage(this.item, 'title');
        }
      },
      itemDuration() {
        return this.item.duration;
      },
      itemViews() {
        return this.item.views || this.item.views === 0 ? this.item.views : -1;
      },
      canPlay() {
        if (this.item) {
          return amsClient.canPlay(this.item);
        }
        return true;
      },

      styleResponsive() {
        let color =
          this.isNotPromotedLive && this.isActiveFeature('live-item-background')
            ? this.item.background_color
            : null;
        return {
          'background-color': color,
        };
      },
      isNotPromotedLive() {
        return (
          this.isLive &&
          !this.isPromotedLive &&
          this.isActiveFeature('live-item-class')
        );
      },
      showLiveBorder() {
        return this.isActiveFeature('live-item-circle') && this.isNotPromotedLive;
      },
    },

    methods: {
      addTransparetion(isVisible, el) {
        if (el && el.target && el.target.style) {
          el.target.style.opacity = isVisible ? 1 : 0.1;
        }
      },
    },
  };
</script>

<style lang="scss">
  .card-thumb-overlay {
    border-bottom-left-radius: $subscribe-border-radius;
    border-bottom-right-radius: $subscribe-border-radius;
    bottom: -1px;
    color: $text-color-white;
    height: 50%;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    width: auto;

    &-color {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
      );
    }
  }

  .card-thumb-overlay-top {
    border-bottom-left-radius: $subscribe-border-radius;
    border-bottom-right-radius: $subscribe-border-radius;
    top: -1px;
    color: $text-color-white;
    height: 50%;
    min-width: 100%;
    overflow: hidden;
    position: absolute;
    width: auto;
  }

  .card-thumb-content {
    border-radius: $base-border-radius;
    height: auto;
    max-height: 100%;

    // margin: auto;
    top: 50%;
    transform: translateY(-50%);

    &.is-live {
      border-radius: 50%;
      padding: 10px;

      &.bordered {
        border: 1px solid $background-color;
        border-bottom-width: 2px;
      }
    }
  }

  .card-thumb-image {
    border-radius: $base-border-radius;
  }

  .card-thumb-add {
    font-size: calc(0.5em + 0.5vw);
    color: $text-color-white;
    line-height: 1;
    padding: 6px 9px;

    // &.sub-label {
    //   font-size: 0.6em;
    // }
  }

  @include media-breakpoint-up('sm') {
    .card-thumb-add {
      font-size: calc(0.5em + 0.5vw);
    }
    .card-thumb-content {
      &.is-live {
        padding: 15px;
      }
    }
  }

  @include media-breakpoint-up('md') {
    .card-thumb-add {
      //font-size: 0.7em; // 12px
      font-size: 13px;
    }

    .card-thumb-content {
      &.is-live {
        padding: 20px;
      }
    }
  }

  @include media-breakpoint-up('lg') {
    .card-thumb-content {
      &.is-live {
        padding: 35px;
      }
    }
  }

  @include media-breakpoint-up('xl') {
    .card-thumb-content {
      &.is-live {
        padding: 50px;
      }
    }
  }

  @media screen and (min-width: 3000px) {
    .card-thumb-content {
      &.is-live {
        padding: 100px;
      }
    }
  }
</style>
