<template>
  <div class="text-nowrap">
    <span class="far fa-eye"/> {{ views | viewsNormalise }}
  </div>
</template>

<script>
  export default {
    name: 'Views',
    props: {
      views: {
        type: Number,
        required: true,
      },
    },
  
  }
</script>
