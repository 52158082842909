<template>
  <div>
    <slot/>
  </div>
</template>
<script>
  import device from "../../service/device-service.js";
  import debounce from "lodash/debounce";

  export default {
    name: 'EventHandler',
    props: {
      enable: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        dragged: false,
      }
    },
    computed : {
      isToutch() {
        return ('ontouchstart' in window)
          || (navigator.MaxTouchPoints > 0)
          || (navigator.msMaxTouchPoints > 0)
          || ('touchstart' in document.documentElement);
      },
    },
    mounted() {
      if (device.isAnyMobile() && this.isToutch) {
        this.addEventsTouch();
      } else {
        this.addEventsMouse();
      }
    },

    updated() {
      if (device.isAnyMobile() && this.isToutch) {
        this.removeEventsTouch();
        this.addEventsTouch();
      } else {
        this.removeEventsMouse();
        this.addEventsMouse();
      }
    },
    beforeDestroy() {
      if (device.isAnyMobile() && this.isToutch) {
        this.removeEventsTouch();
      } else {
        this.removeEventsMouse();
      }
    },
    methods: {
      emitStart(event) {
        this.dragged = !this.dragged;
        if (this.dragged) {
          this.$emit('start', event);
        }
      },

      emitMove(event) {
        if (this.dragged) {
          this.$emit('move', event);
        }
      },

      emitStop(event) {
        if (this.dragged) {
          this.dragged = false;
          this.$emit('stop', event);
        }
      },

      addEventsTouch() {
        this.$el.addEventListener('touchstart', this.emitStart, {passive: true});
        this.$el.addEventListener('touchmove', this.emitMove, {passive: true});
        this.$el.addEventListener('touchend', this.emitStop, {passive: true});
      },
      removeEventsTouch() {
        this.$el.removeEventListener('touchstart', this.emitStart);
        this.$el.removeEventListener('touchmove', this.emitMove);
        this.$el.removeEventListener('touchend', this.emitStop);
      },
      addEventsMouse() {
        this.$el.addEventListener('mousedown', this.emitStart);
        this.$el.addEventListener('mousemove', this.emitMove);
        this.$el.addEventListener('mouseup', this.emitStop);
      },
      removeEventsMouse() {
        this.$el.removeEventListener('mousedown', this.emitStart);
        this.$el.removeEventListener('mousemove', this.emitMove);
        this.$el.removeEventListener('mouseup', this.emitStop);
      },

    },
  }
</script>


<style>

</style>
